import "./firefighter.css";
import { Upload, message } from "antd";
import {useEffect, useRef, useState} from "react";
import {
  AgeSectionContentOptions,
  Buttons,
  ChallengesSectionContentOptions,
  SectionContent,
  SectionContentLeft,
  SectionWrapper,
  SectionWrapperHeader,
  UploaderHint,
} from "./firefighter-style";
import { PageTitle } from "../page-title";
import { Container } from "./style";

const FireFighter = ({ setActiveTab, setFormValues, publicAge, setFormSubmitted, formValues, activeTab }) => {
  const formRef = useRef();
  const firefighterChallenges = useRef([]);
  const [deptCred, setDeptCred] = useState(null);
  const [goverId, setGoverId] = useState(null);
  const finish = useRef(false);

  const [license, setLicense] = useState(null);

  const handleChangeLicense = (info) => {
    if (info.file.status === "done") {
      setLicense(info.file.originFileObj);
    }
  };

  const handleChangeDeptCred = (info) => {
    if (info.file.status === "done") {
      setDeptCred(info.file.originFileObj);
    }
  };

  const handleChangeGoverId = (info) => {
    if (info.file.status === "done") {
      setGoverId(info.file.originFileObj);
    }
  };

  const handleFirefighterChallenges = (e, text) => {
    if (e.target.checked) {
      firefighterChallenges.current.push(text);
    } else {
      firefighterChallenges.current = firefighterChallenges.current.filter(
        (item) => item !== text
      );
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
      return;
    }
    return isJpgOrPng && isLt2M;
  };

  const handleBack = () => {
    setActiveTab("3");
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleContinue = () => {
    if (!firefighterChallenges.current.length) {
      message.error("you should at least select one firefighter challenge");
      return;
    }

    if (firefighterChallenges.current.includes("Fire Truck Challenge") && !license) {
      message.error("Upload a valid fire truck driving license");
      return;
    }

    if (!goverId) {
      message.error("Upload a valid government ID");
      return;
    }

    const values = {
      firefighter_challenges: JSON.stringify(firefighterChallenges.current),
      fire_truck_license: license,
      fire_dept_credentials: deptCred,
      government_id: goverId,
    };

    finish.current = true;
    setFormValues(values);
  };

  useEffect(() => {
    if (finish.current && activeTab === "4") {
      setFormSubmitted(true);
    } else {
      finish.current = false;
    }
  }, [formValues]);

  return (
    <Container>
      <section className="mt-[115px] mb-[199px]">
        <PageTitle
          title="Firefighter Challenges"
          description="Please fill the fields carefully"
        />
        <SectionWrapper>
          <SectionWrapperHeader>Firefighter Challenges</SectionWrapperHeader>
          <SectionContent>
            <SectionContentLeft>
              <h4>Competing Age Category</h4>
            </SectionContentLeft>
            <AgeSectionContentOptions>
              <label className="container border border-[#EEE] rounded-2xl h-[65px] flex items-center">
                <input
                  type="checkbox"
                  disabled
                  checked={publicAge >= 20 && publicAge <= 29}
                />
                <p className="pl-4">20-29 yrs.</p>
                <span className="checkmark"></span>
              </label>
              <label className="container border border-[#EEE] rounded-2xl h-[65px] flex items-center">
                <input
                  type="checkbox"
                  disabled
                  checked={publicAge >= 30 && publicAge <= 39}
                />
                <p className="pl-4">30-39 yrs.</p>
                <span className="checkmark"></span>
              </label>
              <label className="container border border-[#EEE] rounded-2xl h-[65px] flex items-center">
                <input
                  type="checkbox"
                  disabled
                  checked={publicAge >= 40 && publicAge <= 49}
                />
                <p className="pl-4">40-49 yrs.</p>
                <span className="checkmark"></span>
              </label>
              <label className="container border border-[#EEE] rounded-2xl h-[65px] flex items-center">
                <input
                  type="checkbox"
                  disabled
                  checked={publicAge >= 50 && publicAge <= 59}
                />
                <p className="pl-4">50-59 yrs.</p>
                <span className="checkmark"></span>
              </label>
              <label className="container border border-[#EEE] rounded-2xl h-[65px] flex items-center">
                <input type="checkbox" disabled checked={publicAge >= 60}/>
                <p className="pl-4">60+ yrs.</p>
                <span className="checkmark"></span>
              </label>
            </AgeSectionContentOptions>
          </SectionContent>
        </SectionWrapper>

        <SectionWrapper>
          <SectionContent>
            <SectionContentLeft
              additionalstyles={{
                paddingTop: "112px",
                justifyContent: "flex-start",
              }}
            >
              <h4>Firefighter Challenges</h4>
              <p>(Please tick the challenges you wish to participate in)</p>
            </SectionContentLeft>
            <ChallengesSectionContentOptions ref={formRef}>
              <label className="checkmark-cont container border border-[#EEE]  rounded-2xl h-[65px] pl-8 pr-[26px] cursor-pointer flex justify-center items-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleFirefighterChallenges(
                      e,
                      "Toughest Firefighter Alive"
                    );
                  }}
                />
                <span className="checkmark"></span>
                <p>Toughest Firefighter Alive</p>
              </label>
              <label className="checkmark-cont container border border-[#EEE]  rounded-2xl h-[65px] pl-8 pr-[26px] cursor-pointer flex justify-center items-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleFirefighterChallenges(
                      e,
                      "Toughest Firefighter Alive Relay"
                    );
                  }}
                />
                <span className="checkmark"></span>
                <p>Toughest Firefighter Alive Relay</p>
              </label>
              <label className="checkmark-cont  container border border-[#EEE]  rounded-2xl h-[65px] pl-8 pr-[26px] cursor-pointer flex justify-center items-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleFirefighterChallenges(e, "Stair Run Challenge");
                  }}
                />
                <span className="checkmark"></span>
                <p>Stair Run Challenge</p>
              </label>
              <label className="checkmark-cont  container border border-[#EEE]  rounded-2xl h-[65px] pl-8 pr-[26px] cursor-pointer flex justify-center items-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleFirefighterChallenges(e, "Firehouse Cook-off");
                  }}
                />
                <span className="checkmark"></span>
                <p>Firehouse Cook-off</p>
              </label>
              <label className="checkmark-cont  container border border-[#EEE]  rounded-2xl h-[65px] pl-8 pr-[26px] cursor-pointer flex justify-center items-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleFirefighterChallenges(e, "Personal protective equipment");
                  }}
                />
                <span className="checkmark"></span>
                <p>Personal protective equipment</p>
              </label>
              <label className="checkmark-cont  container border border-[#EEE]  rounded-2xl h-[65px] pl-8 pr-[26px] cursor-pointer flex justify-center items-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleFirefighterChallenges(e, "Vehicle Extraction");
                  }}
                />
                <span className="checkmark"></span>
                <p>Vehicle Extraction</p>
              </label>
              <label className="checkmark-cont  container border border-[#EEE]  rounded-2xl h-[65px] pl-8 pr-[26px] cursor-pointer flex justify-center items-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleFirefighterChallenges(e, "High Angled Rescue");
                  }}
                />
                <span className="checkmark"></span>
                <p>High Angled Rescue</p>
              </label>

              <div className="license-cont w-full container border border-[#EEE]  rounded-2xl h-[65px] pl-8 pr-[26px] cursor-pointer flex justify-center items-center">
                <label className="checkmark-cont flex-shrink-0 mr-3">
                  <div className="flex">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        handleFirefighterChallenges(e, "Fire Truck Challenge");
                      }}
                    />
                    <span className="checkmark"></span>
                    <p>Fire Truck Challenge</p>
                  </div>
                </label>
                <Upload
                  accept=".png,.jpeg,.jpg,application/pdf"
                  onChange={handleChangeLicense}
                  customRequest={dummyRequest}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  maxCount={1}
                >
                  <div className="flex justify-center gap-1 items-center text-[#EB5757] text-[11px] relative">
                    Upload your fire truck license
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="23"
                      viewBox="0 0 24 23"
                      fill="none"
                    >
                      <g opacity="0.8">
                        <path
                          d="M4 15.8607V17.7267C4 18.2216 4.21071 18.6962 4.58579 19.0461C4.96086 19.3961 5.46957 19.5927 6 19.5927H18C18.5304 19.5927 19.0391 19.3961 19.4142 19.0461C19.7893 18.6962 20 18.2216 20 17.7267V15.8607M7 8.39686L12 3.73193M12 3.73193L17 8.39686M12 3.73193V14.9278"
                          stroke="#FF4119"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </div>
                </Upload>
              </div>
              <UploaderHint>
                Competitor must be certified and have a valid fire truck driving license.
              </UploaderHint>
            </ChallengesSectionContentOptions>
          </SectionContent>
        </SectionWrapper>

        <SectionWrapper>
          <SectionContent>
            <SectionContentLeft
              additionalstyles={{
                paddingTop: "60px",
                justifyContent: "flex-start",
              }}
            >
              <h4>Scanned documents</h4>
              <p>(Please attach a scanned copy of the following documents)</p>
            </SectionContentLeft>
            <div style={{marginLeft: 20, paddingBottom: 20}}>
              <Upload
                accept=".png,.jpeg,.jpg,application/pdf"
                listType="picture"
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                onChange={handleChangeGoverId}
                multiple={false}
                maxCount={1}
              >
                <section className="uploadInput margin-29">
                  <span>Government ID</span>
                  <div>
                    Upload File
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="23"
                      viewBox="0 0 24 23"
                      fill="none"
                    >
                      <g opacity="0.8">
                        <path
                          d="M4 15.8607V17.7267C4 18.2216 4.21071 18.6962 4.58579 19.0461C4.96086 19.3961 5.46957 19.5927 6 19.5927H18C18.5304 19.5927 19.0391 19.3961 19.4142 19.0461C19.7893 18.6962 20 18.2216 20 17.7267V15.8607M7 8.39686L12 3.73193M12 3.73193L17 8.39686M12 3.73193V14.9278"
                          stroke="#FF4119"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </div>
                </section>
              </Upload>
              <Upload
                accept=".png,.jpeg,.jpg,application/pdf"
                listType="picture"
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                onChange={handleChangeDeptCred}
                multiple={false}
                maxCount={1}
              >
                <section className="uploadInput margin-29">
                  <span>Fire department credentials</span>
                  <div>
                    Upload File
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="23"
                      viewBox="0 0 24 23"
                      fill="none"
                    >
                      <g opacity="0.8">
                        <path
                          d="M4 15.8607V17.7267C4 18.2216 4.21071 18.6962 4.58579 19.0461C4.96086 19.3961 5.46957 19.5927 6 19.5927H18C18.5304 19.5927 19.0391 19.3961 19.4142 19.0461C19.7893 18.6962 20 18.2216 20 17.7267V15.8607M7 8.39686L12 3.73193M12 3.73193L17 8.39686M12 3.73193V14.9278"
                          stroke="#FF4119"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </div>
                </section>
              </Upload>
            </div>
          </SectionContent>
        </SectionWrapper>

        <Buttons className="btns">
          <button onClick={() => handleBack()}>Back</button>
          <button onClick={() => handleContinue()}>
            Continue
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M20.25 12.2256L5.25 12.2256"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.2002 6.20124L20.2502 12.2252L14.2002 18.2502"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </Buttons>
      </section>
    </Container>
  );
};

export default FireFighter;
