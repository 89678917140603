import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { loginSchema } from "../../schema/loginSchema";
import toast, { Toaster } from "react-hot-toast";
import {Form, Spin} from "antd";
import {useState} from "react";
import {InputOTP} from "antd-input-otp";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passedLogin, setPassedLogin] = useState(true);
  const [emailLogged, setEmail] = useState("");
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    console.log(values);
    setLoading(true)
    axios.post("https://locreg-backend.afc2024.com/api/check_otp", {email: emailLogged, code: values.otp.join("")}).then((response) => {
      if (response.data.access_token) {
        localStorage.setItem("user_data", JSON.stringify(response.data));
        navigate("/");
      } else {
        toast.error(response.data[0]);
      }
      setLoading(false)
    }).catch((error) => {
        toast.error("OTP code is invalid");
        console.log(error);
        setLoading(false)
      });
  };

  const initialValues = {
    email: "",
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: loginSchema,
      onSubmit: ({ email }, action) => {
        setLoading(true)
        axios.post("https://locreg-backend.afc2024.com/api/login", {email}).then((response) => {
          setPassedLogin(false)
          setEmail(email)
          setLoading(false)
          }).catch((error) => {
            toast.error("Email or password is incorrect");
            console.log(error);
            setLoading(false)
          });
      },
    });

  return (
    passedLogin ? <form onSubmit={handleSubmit}>
      <Toaster position="top-center" reverseOrder={false} />
      <h1 className="text-[#303030] font-semibold text-[36px] mt-[57px] mb-[46px]">
        Log In <Spin spinning={loading} />
      </h1>
      <section className="grid gap-4">
        <label>
          Email Address <span className="text-[#F12828]">*</span>
        </label>
        <input
          type="email"
          placeholder="Email Address"
          className="placholder:text-[#303030]/40 text-[#303030] text-sm tracking-[0.56px] h-[58px] border border-[#A5A5A5]/50 bg-[#F4F4F4]/50 rounded-[15px] px-[24px] placeholder:opacity-40 focus:outline-none"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          name="email"
        />
        <div
          className={`flex items-center gap-1 text-[14px]
      ${errors.email && touched.email ? "block" : "hidden"}
    `}
        >
          <span className="text-red-500 font-medium">{errors.email}</span>
        </div>
      </section>
      <button
        type="submit"
        className="text-[#FFF] w-full py-[21px] text-base font-semibold leading-6 bg-[#FF4119] rounded-[15px] mt-[32px] text-center focus:outline-none"
      >
        Log In
      </button>
    </form> :
      <Form onFinish={handleFinish} form={form}>
        <Toaster position="top-center" reverseOrder={false}/>
        <h1 className="text-[#303030] font-semibold text-[36px] mt-[57px] mb-[46px]">
          OTP code <Spin spinning={loading}/>
        </h1>
        <section className="grid gap-4">
          <label>
            Enter OTP code that your received on your email <span className="text-[#F12828]">*</span>
          </label>
          <Form.Item label="" name="otp">
            <InputOTP autoSubmit={form} inputType="numeric" length={4}/>
          </Form.Item>
        </section>
          <Form.Item>
            <button
              type="submit"
              className="text-[#FFF] w-full py-[21px] text-base font-semibold leading-6 bg-[#FF4119] rounded-[15px] mt-[32px] text-center focus:outline-none"
            >
              Check OTP
            </button>
          </Form.Item>
      </Form>
);
};

export default Login;
