import {Fragment} from "react";
import {
  Header,
  Description,
  DescriptionContainer,
  Image,
  ImageContainer,
} from "./style";
import { FButton, ButtonContainer } from "../shared/style";
import Footer from "../layout/footer";
import Navbar from "../layout/header";
import {Navigate, useNavigate} from "react-router-dom";
import ArrowElment from "../shared/arrow";
import WelcomeImg from "../../images/welcome.png";
import { Container } from "../register/style";
import "../register/visa.css";

const Home = () => {
  const navigate = useNavigate();

  const register = () => {
    navigate("/register");
  };

  let isLoggedIn = JSON.parse(localStorage.getItem("user_data"));
  if (!isLoggedIn?.access_token) {
    return <Navigate to={"/login"} replace={true} />;
  }

  return (
    <Fragment>
      <Navbar />
      <Header className="mt-20 mb-10">Welcome to the<br />Aramco Firefighter Challenge 2024 (AFC24)<br />Registration Portal</Header>
      <Container>
        <DescriptionContainer>
          <Description>
            Get ready to showcase your abilities and embrace the thrill of
            competition. We believe in your potential and can't wait to witness
            your excellence.
          </Description>
        </DescriptionContainer>

        <section className="note-par" style={{marginTop: 30, textAlign: "left"}}>
          <div className="dark-note" style={{padding: 0, background: "none", width: "auto", marginBottom: 10, textAlign: "left"}}>
            <div className="warning" style={{width: "auto", justifyContent: "left"}}>
              <span>
                  To complete the registration process in this portal you will need the following documentation in PDF<br/>
format:<br/>
. Fire Department I.D. or letter confirming employment<br/>
. Fire Truck Driver’s license (if participating in the Fire Truck Challenge)
                </span>
            </div>
          </div>
        </section>

        <ButtonContainer>
          <FButton onClick={register}>
            Register <ArrowElment/>
          </FButton>
        </ButtonContainer>
        <ImageContainer style={{textAlign: "center"}}>
          <Image src={WelcomeImg} alt=""/>
        </ImageContainer>
      </Container>
      <Footer/>
    </Fragment>
  );
};
export default Home;
