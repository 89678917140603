import {useRef} from "react";
import { Container, Label, StyledRadio } from "./style";
import {Form, Radio, Row, Input, Col, Select} from "antd";
import { FormButton, BackButton } from "../shared/style";
import ArrowElment from "../shared/arrow";
import { PageTitle } from "../page-title";

const FirstServiceInformation = ({ setActiveTab, setFormValues }) => {
  const form = useRef();

  const handleContinue = () => {
    const entries = form.current.getFieldsValue();

    const values = {
      firefighter_status: entries.firefighter_status,
      fire_service_name: entries.fire_service_name,
      job_title: entries.job_title,
      firefighter_status_other: entries.firefighter_status_other ?? "",
    };

    setFormValues(values);
    setActiveTab("3");
  };

  const handleBack = () => {
    setActiveTab("1");
  };

  return (
    <Container>
      <PageTitle
        title="Fire Service Information"
        description="Please fill the fields carefully"
      />
      <div style={{ maxWidth: "100%", width: "815px", margin: "10px auto" }}>
        <Form ref={form}>
          <Label>Firefighter Status</Label>

          <Form.Item name="firefighter_status">
            <Radio.Group name="radiogroup">
              <Row>
                <Col xs={24} sm={8} md={5} lg={5} xl={7}>
                  <StyledRadio value="FullTime">Full Time</StyledRadio>
                </Col>
                <Col xs={24} sm={8} md={5} lg={5} xl={7}>
                  <StyledRadio value="PartTime">Part Time</StyledRadio>
                </Col>
                <Col xs={24} sm={8} md={5} lg={5} xl={7}>
                  <StyledRadio value="Volunteer">Volunteer</StyledRadio>
                </Col>
                <Col xs={24} sm={8} md={5} lg={5} xl={7}>
                  <StyledRadio value="Backup">Backup</StyledRadio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="fire_service_name"
            style={{ marginTop: "30px", marginRight: "10px" }}
            rules={[
              {
                required: true,
                message: "Please enter Name of the fire service you are employed by.",
              },
              { max: 50, message: "Maximum 50 characters allowed." },
            ]}
          >
            <Select
              placeholder="Name of the fire service you are employed by"
              style={{ width: "100%", height: 54, textAlign: "left" }}
              options={[
                {
                  label: 'Divisional Teams',
                  options: [
                    { label: 'ABQ', value: 'ABQ' },
                    { label: 'DH', value: 'DH' },
                    { label: 'NRA', value: 'NRA' },
                    { label: 'UDH', value: 'UDH' },
                    { label: 'RT', value: 'RT' },
                    { label: 'WEST/Central', value: 'WEST/Central' },
                    { label: 'West North', value: 'West North' },
                    { label: 'West South', value: 'West South' },
                  ],
                },
                {
                  label: 'National Teams',
                  options: [
                    { label: 'Saudi Aramco', value: 'Saudi Aramco' },
                    { label: 'Civil Defense', value: 'Civil Defense' },
                    { label: 'SATORP', value: 'SATORP' },
                    { label: 'SAMREF', value: 'SAMREF' },
                    { label: 'YASFREF', value: 'YASFREF' },
                    { label: 'LUBEREF', value: 'LUBEREF' },
                    { label: 'SABIC', value: 'SABIC' },
                    { label: 'SICLI', value: 'SICLI' },
                    { label: 'GACA', value: 'GACA' },
                    { label: 'SAR', value: 'SAR' },
                    { label: 'Royal Commission', value: 'Royal Commission' },
                    { label: 'Saudi Electric Company', value: 'Saudi Electric Company' },
                    { label: 'MA\'ADEN', value: 'MA\'ADEN' },
                  ],
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="job_title"
            style={{ marginTop: "30px", marginRight: "10px" }}
            rules={[
              {
                required: true,
                message: "Please enter your Job Title ",
              },
              { max: 50, message: "Maximum 50 characters allowed." },
            ]}
          >
            <Input
              placeholder="Job Title"
              style={{
                height: "54px",
              }}
            />
          </Form.Item>
          <Row justify="center" className="mt-[36px]">
            <Col xs={24} sm={24} md={12}>
              <BackButton onClick={() => handleBack()}>Back</BackButton>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <FormButton onClick={() => handleContinue()}>
                Continue <ArrowElment />
              </FormButton>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ height: 50 }} />
    </Container>
  );
};
export default FirstServiceInformation;
